<template>
  <div v-if="isInvestor">
    <DashboardInvestor/>
  </div>
  <div v-else-if="isBroker">
    <DashboardBroker/>
  </div>
  <div v-else-if="isCompany">
    <DashboardCompany/>
  </div>
  <div v-else-if="isAdm">
    <DashboardAdm/>
  </div>
  <div v-else>
    <b-card>
      <b-card-text>Seja bem-vindo!</b-card-text>
    </b-card>
    <!-- broker-account -->
    <b-card v-if="this.$store.state.user.isDocPending === true">
      <b-link
        :to="{
          name: 'broker-account',
        }">
        Editar seus dados
      </b-link>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import DashboardInvestor from './DashboardInvestor.vue'
import DashboardBroker from './DashboardBroker.vue'
import DashboardCompany from './DashboardCompany.vue'
import DashboardAdm from './DashboardAdm.vue'

export default {
  components: {
    BCard,
    DashboardBroker,
    BCardText,
    BLink,
    DashboardInvestor,
    DashboardCompany,
    DashboardAdm,
  },

  data() {
    return {
      isInvestor: false,
      isBroker: false,
      isCompany: false,
      isAdm: false
    }
  },
  methods: {
    setRoleInvestor() {
      this.isInvestor = localStorage.getItem('role') === 'INVESTOR'
      this.isBroker = localStorage.getItem('role') === 'BROKER'
      this.isCompany = localStorage.getItem('role') === 'COMPANY'
      this.isAdm = localStorage.getItem('role') === 'Admin'
    }
  },

  mounted() {
    this.setRoleInvestor()
  },
  created() {
    return this.$store.state.user.isDocPending === true
      ? this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      : this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
}
</script>

<style>
body {
  background-color: #f8f8f8 !important;
}
</style>
