<template>
  <div>
    <div class="div-principal">
      <Loader v-if="loading"/>
      <div class="cards-left">
        <div class="firts-two-lines">
          <div class="first-lines-company">
            <div class="card-style-company">
              <div style="width: 100%;">
                <div class="row-title-adm">
                  <div class="class-title-adm">
                    <div class="title-cards-adm">Número de Projetos</div>
                    <b-icon v-b-popover.hover.top="'Total de projetos/obras cadastradas na plataforma.'" 
                    class="color-icon-adm" icon="question-circle" />
                  </div>
                </div>
                <div class="text-value-adm">
                  {{values.countProjects}}
                </div>
              </div>
            </div>
            <div class="card-style-company margin-left">
              <div style="width: 100%;">
                <div class="row-title-adm">
                  <div class="class-title-adm">
                    <div class="title-cards-adm">Número de Imóveis</div>
                    <b-icon v-b-popover.hover.top="'Total de unidades cadastradas na plataforma. Um projeto pode ter mais de uma unidade cadastrada.'" 
                    class="color-icon-adm" icon="question-circle" />
                  </div>
                </div>
                <div class="text-value-adm">
                  {{values.countImmobiles}}
                </div>
              </div>
            </div>
          </div>
          <div class="first-lines-company secound">
            <div class="card-style-company">
              <div style="width: 100%;">
                <div class="row-title-adm">
                  <div class="class-title-adm">
                    <div class="title-cards-adm">Imóveis em Captação</div>
                    <b-icon v-b-popover.hover.top="'Total de unidades com cotas disponíveis para venda.'" 
                    class="color-icon-adm" icon="question-circle" />
                  </div>
                </div>
                <div class="text-value-adm">
                  {{values.captureImmobiles}}
                </div>
              </div>
            </div>
            <div class="card-style-company margin-left">
              <div style="width: 100%;">
                <div class="row-title-adm">
                  <div class="class-title-adm">
                    <div class="title-cards-adm">Imóveis Encerrados</div>
                    <b-icon v-b-popover.hover.top="'Total de unidades com cotas 100% vendidas ou imóvel com venda final já realizada.'" 
                    class="color-icon-adm" icon="question-circle" />
                  </div>
                </div>
                <div class="text-value-adm">
                  {{values.immobilesClosed}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-style-company recepted">
          <div style="width: 100%;">
            <div class="row-title-adm">
              <div class="class-title-adm">
                <div class="title-cards-adm">Valores Recebidos</div>
                <b-icon v-b-popover.hover.top="'Total recebido pela venda de cotas.'" 
                class="color-icon-adm" icon="question-circle" />
              </div>
            </div>
            <div class="text-value-adm">
              {{formatCurrencyPrice(values.valuesRecepted)}}
            </div>
          </div>
        </div>
      </div>
      <div class="box-right-company">
        <div id="box-status" class="card-style overview">
          <div class="body-padding-overview">
            <div class="title-cards">Overview de Captação</div>
            <div style="display: flex; margin-top: 3%">
              <div style="width: 30%;">Imóvel</div>
              <div style="width: 22%;">Cotas Vendidas</div>
              <div style="width: 18%;">Captação</div>
              <div style="width: 30%;">Prazo 50% Captação</div>
            </div>
            <div v-for="inv in values.overview" :key="inv.name" class="status-lines">
              <div style="display: flex; margin-top: 2%">
                <div style="width: 30%;">{{inv.immobileName}}</div>
                <div style="width: 22%;">{{inv.soldQuotes}}</div>
                <div style="width: 18%;">{{inv.captation}}%</div>
                <div style="width: 30%;">{{inv.deadline50 !== null ? formatDate(inv.deadline50) : ''}}</div>
              </div>
            </div>
          </div>
          <div style="width: 1%"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BButton, BCardText, BLink, BRow, BCol, VBPopover, BImg } from 'bootstrap-vue'
import Loader from '../../components/Loader.vue'
import formatter from '@/mixins/formatter'
import ConstructionCompanyService from '@/services/ConstructionCompany/ConstructionCompanyService'

export default {
  components: {
    BCard,
    BCol,
    BCardText,
    BButton,
    BLink,
    BImg,
    BRow,
    Loader,
  },
  directives: {
    'b-popover': VBPopover,
  },
  mixins: [formatter],
  data() {
    return {
      values: {},
      loading: false,
      donutSelect: false,
      popoverTotalInvestido: 'Valor total que você tem aplicado na plataforma',
      popoverVlrEstimado: 'Valor total investido mais a rentabilidade estimada dos investimentos que você possui na carteira',
      popoverStatusInv: 'Quantidade de investidores, total captado e valorização do investimento de cada imóvel',
      popoverInvRealiz: 'Quantidade de imóveis que você já comprou',
      popoverInvAndamento: 'Quantidade de imóveis que você possui que ainda não estão com a operação finalizada',
      popoverInvLiquidado: 'Quantidade de imóveis que você possui que ainda já estão com a operação finalizada',
      popoverGraphics: 'Distribuição dos investimentos por imóvel',
    }
  },

  mounted() {
    this.getResume()
  },
  created() {
    return this.$store.state.user.isDocPending === true
      ? this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      : this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  methods: {
    async getResume() {
      this.loading = true
      this.values = (await ConstructionCompanyService.getResumeDashboard()).data.result
      this.loading = false
    },
    selectLineGraphic(){
      this.donutSelect = false
    },
    selectDonutGraphic(){
      this.donutSelect = true
    },

    formatDate(date) {
      return formatter.formatDate(date)
    }
  },
}
</script>
<style  lang="scss">

.card-style-company {
  border: 1px solid #D3D8E3;
  border-radius: 10px;
  background: white;
  width: 40% !important;
  height: 10em;
  box-sizing: border-box;
}
.margin-left{
  margin-left: 4%;
}
.box-right-company{
  width: 40%;
}
.body-padding-overview {
  margin-top: 2%;
  margin-left: 3%;
}
.secound {
  margin-top: 3%;
}
.overview {
  height: 100%;
  max-height: 40em;
  overflow-y:scroll;
}
.recepted{
  margin-top: 6%;
  width: 45% !important;
}
.first-lines-company { 
  display: flex;
  height: 50%;
  margin-right: -12%;
}
</style>