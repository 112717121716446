<template>
  <div class="dashboard-broker">
    <Loader v-if="loading"/>
    <div class="cards-left-b">
      <div style="display: flex">
        <div class="card-total card-left-b card-style">
          <div class="body-padding">
            <div class="title-cards-b">Comissões Recebidas</div>
            <div class="value-cards">{{ formatCurrencyPrice(values.commissionsReceived) }}</div>
          </div>
          <div>            
              <b-icon v-b-popover.hover.top="popoverRecebido" class="color-icon-broker" icon="question-circle" />
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div class="card-left-b card-style">
          <div class="body-padding">
            <div class="title-cards-b">Comissões Pendentes</div>
            <div class="value-cards">{{formatCurrencyPrice(values.commissionsPending)}}</div>
          </div>
          <div>            
              <b-icon v-b-popover.hover.top="popoverReceber" class="color-icon-broker" icon="question-circle" />
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div class="card-left-b card-style">
          <div class="body-padding">
            <div class="title-cards-b">Previsão</div>
            <div class="value-cards">{{ formatCurrencyPrice(values.commissionsPending + values.commissionsReceived) }}</div>
          </div>
          <div>            
              <b-icon v-b-popover.hover.top="popoverTotal" class="color-icon-broker" icon="question-circle" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-style card-historic">
        <div class="body-padding-historic">
          <div class="title-cards-b">Histórico de comissões</div>
          <hr>
          <div v-for="h in values.commissions" :key="h.id">
            <div  class="line-historic">
              <div class="value-historic">{{h.immobileName}}</div>
              <div class="value-historic value-historic-left">{{formatCurrencyPrice(h.value)}}</div>
              <div class="value-historic value-historic-left">{{h.date}}</div>
              <div class="value-historic value-historic-left">
                <b-badge class="status" :variant="statusInvestmentVariant(h.status)">
                   {{ statusInvestmentVariantName(h.status) }}
                </b-badge>                
              </div>
            </div>
            <hr>
          </div>
          <b-button v-if="values.count > 8" class="ml-12 button-disabled" v-ripple.400="'rgba(255, 255, 255, 0.5)'" variant="primary" @click="$router.push('investments/broker-commission')">
            Ver mais
          </b-button>
        </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol, VBPopover, BBadge, BButton } from 'bootstrap-vue'
import Loader from '../../components/Loader.vue'
import formatter from '@/mixins/formatter'
import BrokerInvestmentService from '@/services/Investment/BrokerInvestmentService'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCol,
    BCardText,
    BBadge,
    BLink,
    BRow,
    VBPopover,
    Loader,
    BButton
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [formatter],
  data() {
    return {
      values: {},
      loading: false,
      popoverRecebido: 'Valor total de comissões recebidas',
      popoverReceber: 'Valor total de comissões pendentes de pagamento',
      popoverTotal: 'Valor total de comissões acumuladas',
    }
  },

  mounted() {
    this.getResume()
  },
  created() {
    return this.$store.state.user.isDocPending === true
      ? this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      : this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  methods: {
    async getResume() {
      this.loading = true
      this.values =  (await BrokerInvestmentService.getDashboardBroker()).data.result
      this.loading = false
    },
  },

  computed: {    
    statusInvestmentVariantName() {
      const statusName = {
        1: 'Pago',
        2: 'Pendente'
      }
      return status => statusName[status]
    },

    statusInvestmentVariant() {
      const statusColor = {
        1: 'light-success',
        2: 'light-primary'
      }

      return status => statusColor[status]
    }, 
  }
}
</script>

<style>
  .dashboard-broker{
    display: flex;
  }
  body {
    background-color: #f8f8f8 !important;
  }
  hr {
    margin-right: 2em;
  }
  .card-total {
    background-color: #FFF7E8 !important;
    border-color: #FFC85E !important;
  }
  .color-icon-broker {
    color: #b4b7bd
  }
  .card-style {
    border: 1px solid #D3D8E3;
    box-sizing: border-box;
    border-radius: 10px;
    background: white;   
  }
  .bi-person.b-icon.bi {
    width: 2.5em !important;
    height: 1.5em !important;
    margin-left: 50%;
  }
  .bi-question-circle.color-icon-broker.b-icon.bi {
    width: 100% !important;
    height: 12% !important;
    margin-top: 1em;
  }
  .bi-question-circle.color-icon-broker.icon-question-status.b-icon.bi {
    width: 35% !important;
    height: 13% !important;
    margin-top: 0em !important;
  }
  .bi-dash-circle.b-icon.bi {  
    width: 40% !important;
    height: 40% !important;
  }

  .line-historic {
    display: flex; 
  }
  .value-historic-left {
    margin-left: 5%;   
  }
  .button-disabled {
  font-size: 1em;
  width: 80%;
  box-shadow: none !important;
  background-color: white !important;
  border-color: rgb(0, 0, 0) !important;
  color: grey !important;
  }

 @media (min-width: 1200px) {
  .cards-left-b {
    width: 30%;
  }
  .card-left-b {
    display: flex;
    justify-content: space-around;
    width: 95%;
    margin-top: 5%;
    min-height: 12em;
  }
  .card-historic {
    display: flex;
    justify-content: space-around;
    width: 40%;
    margin-top: 1.5%;
  }
  .div-center {
    width: 40%;
  }
  .title-cards-b {
    color: #b4b7bd;
    width: 100% !important;
    font-size: 1.1em;
  }
  .value-cards {
    width: 100% !important;
    height: 20% !important;
    font-size: 2.5em;
    font-weight: bold;
    color: #676d7d;
  }
  .body-padding{
    margin-top: 5%;
  }

  .body-padding-historic {
    width: 100%;
    margin-left: 2em;
    margin-top: 10%;
  }
  .status{
    min-width: 8em;
  }
 }
  
  @media (min-width: 1600px) {
  .cards-left-b {
    width: 20%;
  }
  .card-left-b {
    display: flex;
    justify-content: space-around;
    width: 95%;
    margin-top: 5%;
    min-height: 12em;
  }
  .card-historic {
    display: flex;
    justify-content: space-around;
    width: 30%;
    margin-top: 1%;
    margin-left: 0%;
  }
  .title-cards-b {
    color: #b4b7bd;
    width: 100% !important;
    font-size: 1.1em;
  }
  .value-cards {
    width: 100% !important;
    height: 20% !important;
    font-size: 100%;
    font-weight: bold;
    color: #676d7d;
    font-size: 2.5em;
  }
  .value-historic {   
    font-size: 1em;
    font-weight: bold;
  }
  .body-padding-historic {
    margin-top: 10%;
  }
 }
 
@media (max-width: 1000px) {
  .title-cards-b {
    text-align: center;
  }
  .value-cards{
    text-align: center;
    margin-left: 0%;
  }
  .dashboard-broker{
    display: block;
  }
  .card-left-b{
    margin-top: 5%;
    display: flex;
    height: 7em !important;
    width: 100%;
  }
  .card-historic{
    margin-top: 5%;
  }
  .card-historic hr{
    margin-left: 2%;
    margin-right: 2%;
  }
  .body-padding {
    width: 90%;
    margin-top: 8%;
  }
  .line-historic {
    margin-left: 5%;
  }
  .body-padding-historic {
    margin-top: 5%;
  }
}
</style>
