<template>
  <b-form @submit.prevent>
    <div class="div-principal">
      <Loader v-if="loading"/>
      <div class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Imóveis Cadastrados</div>
              <b-icon v-b-popover.hover.top="'Total de imóveis cadastrados no sistema, independente do status.'" 
              class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{ values.imoveisCadastrados }}
          </div>
        </div>
      </div>
      <div class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Imóveis em Captação</div>
              <b-icon v-b-popover.hover.top="'Total de imóveis com cotas disponíveis.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{ values.imoveisCaptacao }}
          </div>
        </div>
      </div>
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Imóveis Encerrados</div>
              <b-icon v-b-popover.hover.top="'Total de imóveis com cotas encerradas. Aguardando venda final.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{ values.imoveisEncerrados }}
          </div>
        </div>
      </div>
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Imóveis Vendidos</div>
              <b-icon v-b-popover.hover.top="'Total de imóveis com venda final finalizada.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{ values.imoveisVendidos }}
          </div>
        </div>
      </div>
    </div>
    <div class="div-principal">
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Cotas Disponíveis</div>
              <b-icon v-b-popover.hover.top="'Total de cotas disponíveis para venda. Em quantidade e valor.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{ values.cotasDisponiveis }}
          </div>
          <div class="text-value-adm">
            {{formatCurrencyPrice(values.cotasDisponiveisValor)}}
          </div>
        </div>
      </div>
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Cotas Vendidas</div>
              <b-icon v-b-popover.hover.top="'Total de cotas vendidas. Em quantidade e valor.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{values.cotasVendidas}}
          </div>
          <div class="text-value-adm">
            {{formatCurrencyPrice(values.cotasVendidasValor)}}
          </div>
        </div>
      </div>
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Comissões de Cotas<br> Recebidas</div>
              <b-icon v-b-popover.hover.top="'Total de comissões recebidas pela plataforma'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{formatCurrencyPrice(values.comissoesCotasRecebidasValor)}}
          </div>
        </div>
      </div>
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Comissões Pagas</div>
              <b-icon v-b-popover.hover.top="'Total de comissões pagas à corretores.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{formatCurrencyPrice(values.comissoesPagasValor)}}
          </div>
        </div>
      </div>
    </div>
    <div class="div-principal">
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Comissões a Pagar</div>
              <b-icon v-b-popover.hover.top="'Total de comissões a pagar para corretores'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{formatCurrencyPrice(values.comissoesAPagarValor)}}
          </div>
        </div>
      </div>
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Quantidade de Investidores</div>
              <b-icon v-b-popover.hover.top="'Total de investidores cadastrados na plataforma.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{values.qtdInvestidores}}
          </div>
        </div>
      </div>
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Quantidade de Corretores</div>
              <b-icon v-b-popover.hover.top="'Total de corretores cadastrados na plataforma.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{values.qtdCorretores}}
          </div>
        </div>
      </div>
      <div class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Quantidade de Construtoras</div>
              <b-icon v-b-popover.hover.top="'Total de construtoras cadastrados na plataforma.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{values.qtdConstrutoras}}
          </div>
        </div>
      </div>
    </div>
    <div class="div-principal">
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Cotas Vendidas por Corretores</div>
              <b-icon v-b-popover.hover.top="'Total de cotas vendidas por corretores.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            {{values.cotasVendidasCorretores}}
          </div>
        </div>
      </div>
      <div  class="card-style-adm">
        <div style="width: 100%;">
          <div class="row-title-adm">
            <div class="class-title-adm">
              <div class="title-cards-adm">Valorização dos Imóveis</div>
              <b-icon v-b-popover.hover.top="'Média de valorização dos imóveis da plataforma, total de todos os imóveis.'" class="color-icon-adm" icon="question-circle" />
            </div>
          </div>
          <div class="text-value-adm">
            0%
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { BCard, BForm, BButton, BCardText, BLink, BRow, BCol, VBPopover, BImg } from 'bootstrap-vue'
import Loader from '../../components/Loader.vue'
import formatter from '@/mixins/formatter'
import AdmService from '@/services/AdmService'

export default {
  components: {
    BCard,
    BCol,
    BCardText,
    BButton,
    BLink,
    BImg,
    BRow,
    Loader,
    BForm,
  },
  directives: {
    'b-popover': VBPopover,
  },
  mixins: [formatter],
  data() {
    return {
      values: {},
      loading: false,
    }
  },

  mounted() {
    this.getResume()
  },
  created() {
    return this.$store.state.user.isDocPending === true
      ? this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      : this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  methods: {
    async getResume() {
      this.loading = true
      this.values = (await AdmService.getResumeDashboard()).data.result
      this.loading = false
    },
    selectLineGraphic(){
      this.donutSelect = false
    },
    selectDonutGraphic(){
      this.donutSelect = true
    },
    
    talkSpecialist(){
      this.talkSpecialistData.phone = this.talkSpecialistData.phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
      return this.talkSpecialistData.phone !== null ? `https://wa.me/55${this.talkSpecialistData.phone}?text=${this.talkSpecialistData.msg}` : ''
    },

    formatDate(date) {
      return formatter.formatDate(date)
    }
  },
}
</script>
<style  lang="scss">

.title-cards-adm {
  margin-top: 5%;
  margin-left: 2%;
  font-size: 1.4em;
  color: #b4b7bd;
  width: 100% !important;
  font-size: 1.1em;
}

.card-style-adm {
  width: 20% !important;
  height: 10em;
  margin-right: 1.5%;
  margin-top: 1.5%;
  border: 1px solid #D3D8E3;
  box-sizing: border-box;
  border-radius: 10px;
  background: white;   
}

.row-title-adm {
  width: 100%;
}
.class-title-adm{
  display: flex; 
  width: 100%;
}

.text-value-adm{
  margin-left: 8%;
  font-size: 2em;
}

.color-icon-adm {
  margin-left: -15%;
  margin-top: 6%;
  color: #b4b7bd
}
</style>