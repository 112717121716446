<template>
  <div>
    <div class="div-principal">
      <Loader v-if="loading"/>
      <div class="cards-left">
        <div class="firts-two-lines">
          <div class="first-lines">
            <div class="card-total card-left card-style">
              <div class="body-padding">
                <div class="title-cards">Valor Total Investido</div>
                <div class="value-cards">{{ formatCurrencyPrice(values.total) }}</div>
              </div>
              <div class="icon-about">            
                  <b-icon v-b-popover.hover.top="popoverTotalInvestido" class="color-icon" icon="question-circle" />
              </div>
            </div>
            <div class="card-right card-style">
              <div class="body-padding">
                <div class="title-cards">Valor Estimado</div>
                <div class="value-cards">{{formatCurrencyPrice(values.valueEstimated)}}</div>
              </div>
              <div class="icon-about">            
                  <b-icon v-b-popover.hover.top="popoverVlrEstimado" class="color-icon" icon="question-circle" />
              </div>
            </div>
          </div>
          <div class="first-lines">
            <div class="card-left card-style">
              <div class="body-padding" style="width: 80%;">
                <div class="title-cards">Investimentos Realizados</div>
                <div class="value-cards">{{values.totalInvestmentCount}}</div>
              </div>
              <div class="icon-about">            
                  <b-icon v-b-popover.hover.top="popoverInvRealiz" class="color-icon" icon="question-circle" />
              </div>
            </div>
            <div class="card-right card-style">
              <div class="body-padding" style="width: 80%;">
                <div class="title-cards">Investimentos em andamento</div>
                <div class="value-cards">{{values.investmentProgressCount}}</div>
              </div>
              <div class="icon-about">            
                  <b-icon v-b-popover.hover.top="popoverInvAndamento" class="color-icon" icon="question-circle" />
              </div>
          </div>
          </div>
        </div>
        <div>
          <div class="card-style card-liquidado">
            <div class="body-padding-liquidado">
              <div class="title-cards title-mobile">Investimentos Vendidos</div>
              <div class="value-cards">{{values.liquidatedInvestmentCount}}</div>
            </div>
            <div class="icon-liq">            
                <b-icon v-b-popover.hover.top="popoverInvLiquidado" class="color-icon" icon="question-circle" />
            </div>
          </div>
        </div>
      </div>
      <div class="box-right">
        <div id="box-status" class="card-style card-status">
          <div class="body-padding-status">
            <div v-if="values.investments.length > 0" class="title-cards">Status de Investimento</div>
            <div class="msg-non-inv" v-if="values.investments.length === 0"> 
              <div class="title-cards" style="margin-bottom: 2em;">Sem investimentos</div>
              <b-icon class="color-icon" icon="dash-circle" />
            </div>
            <div v-for="inv in values.investments" :key="inv.name" class="status-lines">
              <div class="line-one-status">
                <div class="nome-imovel-status">{{inv.name}}</div>                    
                <div class="qtd-investidores color-icon"> <b-icon class="color-icon" icon="person" /> {{inv.countInvestors}}</div> 
              </div>
              <div class="line-two-status">
                <div class="color-icon percent-capted">{{inv.capturedQuotas}}% Captado</div>
                <div v-if="inv.isValuedInvestment == 2" class="text-valorization" style="color: #06b606;">
                  {{formatCurrencyPrice(inv.value)}}
                  <b-icon style="margin-left: 1%" icon="caret-up-fill"/>
                </div>
                <div v-else-if="inv.isValuedInvestment == 3" class="text-valorization" style="color: #d10303;">
                  <b-icon style="margin-left: 1%" icon="caret-down-fill"/>
                  {{formatCurrencyPrice(inv.value)}}
                </div>
                <div v-else class="text-valorization" style="color: #b4b7bd;">
                  {{formatCurrencyPrice(inv.value)}}
                </div>
              </div>
              <hr>
            </div>
          </div>
          <div style="width: 1%"></div>
        </div>
        <div class="talk-card">
          <div class="desc-talk">
            <div>Está com dúvidas de como </div>
            <div>acompanhar os seus </div>
            <div>investimentos? </div>
            <div class="talk-vector">
              <b-img class="icon-talk" src="@/assets/images/pages/vector-talk.png" />
            </div>
            <div class="left margin-left" style="position: absolute;">
              <a :href="talkSpecialist()" target="_blank"> 
                <b-button class="btn btn-primary button-talk"> Falar com especialista</b-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-graphics">
      <div style="display: flex; width: 50%;">
        <div class="title-card-graphic">Graficos e Distribuição</div> 
        <div class="title-card-graphic" style="margin-left: 1em; color: #D3D8E3 !important"><b-icon v-b-popover.hover.top="popoverVlrEstimado"  icon="question-circle" /></div>
      </div>
      <div style="display: flex; margin-left: 25%;">
        <b-link @click="selectLineGraphic">
          <div v-if="donutSelect=== true" class="option-select-graphic graphic-line-non-select "><b-icon class="icon-select-graphic" icon="bar-chart" /></div>
          <div v-else class="option-select-graphic graphic-line-select"><b-icon class="icon-select-graphic" icon="bar-chart" /></div>
        </b-link>
        <b-link @click="selectDonutGraphic" style="margin-left: 50%;">
          <div v-if="donutSelect=== true" class="option-select-graphic graphic-donut-select"><b-icon class="icon-select-graphic" icon="pie-chart" /></div>
          <div v-else class="option-select-graphic graphic-donut-non-select"><b-icon class="icon-select-graphic" icon="pie-chart" /></div>          
        </b-link>
      </div>
    </div>
    <div v-if="donutSelect=== true"><ImmobilesInvestorDonut/></div>
    <div v-else><ValorizationImmobilesInvestorLine/></div>
  </div>
</template>

<script>
import { BCard, BButton, BCardText, BLink, BRow, BCol, VBPopover, BImg } from 'bootstrap-vue'
import InvestmentService from '../../services/Investment/InvestmentService'
import Loader from '../../components/Loader.vue'
import formatter from '@/mixins/formatter'
import ValorizationImmobilesInvestorLine from './investor/ValorizationImmobilesInvestorLine'
import ImmobilesInvestorDonut from './investor/ImmobilesInvestorDonut'
import TalkSpecialistConfigService from '@/services/Investment/TalkSpecialistConfigService'

export default {
  components: {
    BCard,
    BCol,
    BCardText,
    BButton,
    BLink,
    BImg,
    ValorizationImmobilesInvestorLine,
    ImmobilesInvestorDonut,
    BRow,
    VBPopover,
    Loader,
  },
  directives: {
    'b-popover': VBPopover,
  },
  mixins: [formatter],
  data() {
    return {
      values: {investments:[]},
      loading: false,
      donutSelect: false,
      popoverTotalInvestido: 'Valor total que você tem aplicado na plataforma',
      popoverVlrEstimado: 'Valor total investido mais a rentabilidade estimada dos investimentos que você possui na carteira',
      popoverStatusInv: 'Quantidade de investidores, total captado e valorização do investimento de cada imóvel',
      popoverInvRealiz: 'Quantidade de imóveis que você já comprou',
      popoverInvAndamento: 'Quantidade de imóveis que você possui que ainda não estão com a operação finalizada',
      popoverInvLiquidado: 'Quantidade de imóveis que você comprou que já foram vendidos e estão com a operação finalizada',
      popoverGraphics: 'Distribuição dos investimentos por imóvel',
    }
  },

  mounted() {
    this.getResume()
    this.getTaklSpecialistData()
  },
  created() {
    return this.$store.state.user.isDocPending === true
      ? this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      : this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  methods: {
    async getResume() {
      this.loading = true
      this.values = (await InvestmentService.getResumeDashboard()).data.result
      this.loading = false
    },
    selectLineGraphic(){
      this.donutSelect = false
    },
    selectDonutGraphic(){
      this.donutSelect = true
    },
    async getTaklSpecialistData(){
      this.talkSpecialistData = (await TalkSpecialistConfigService.GetTaskSpecialistConfigForEdit()).data.result.taskSpecialistConfig
    },
    
    talkSpecialist(){
      if(this.talkSpecialistData.phone) {
        this.talkSpecialistData.phone = this.talkSpecialistData.phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
        return this.talkSpecialistData.phone !== null ? `https://wa.me/55${this.talkSpecialistData.phone}?text=${this.talkSpecialistData.msg}` : ''
      } else {
        return ''
      }
    },
  },
}
</script>
<style  lang="scss">
@import './dashboard_investor.scss';

.talk-vector {
  display: contents;
}
.icon-talk {
  width: 28%;
  float: right !important;
  margin-right: 10%;
  margin-top: -20%;
}
#box-status::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#box-status::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
#box-status::-webkit-scrollbar-thumb {
  background: #F8AC18;
  border-radius: 10px;
}
</style>