<template>
  <div>
    <div v-if="series.length === 0" class="nothing">Você ainda não possui cotas imobiliárias. Faça sua compra, e acompanhe a distribuição da sua carteira por aqui!</div>
    <div class="card-compare-investment-donut">
      <VueApexCharts v-if="series.length > 0" width="500" type="donut" :options="options" :series="series"></VueApexCharts>
    </div>
  </div>
</template>

<script lang="ts">
import { } from 'bootstrap-vue'
import Doughnut from '@/components/charts/Doughnut'
import VueApexCharts from 'vue-apexcharts'
import ImmobileMarketPriceService from '@/services/Investment/ImmobileMarketPriceService'

export default {
  name: 'CardValorizationInvestmentComponent',
  components: {
    Doughnut,
    VueApexCharts
  },

  props: [
    'immobileId',
  ],
  mounted() {
    this.fillData()
    this.renderChart(this.chartData, this.options)
  },

  computed: {
    myStyles() {
      return {
        height: '350px',
      }
    },
  },

  data() {
    return {
      options: {
        labels: [],
        legend: {
          position: 'bottom'
        },
      },
      series: []
    }
  },
  methods: {
    async fillData() {
      const { result } =  (await ImmobileMarketPriceService.getImmobileDistribution()).data
      result.forEach(v => {
        this.series.push(v.value)
        this.options.labels.push(v.immobileName)
      })
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    back() {
      this.$emit('onBack')
    },
  },
}
</script>

<style>
    .nothing {
      margin-top: 3%;
      font-size: 1.5em;
      margin-bottom: 8%;
    }
    .card-compare-investment-donut {
      margin-top: 2%;
      width: 75%;
      display: inline-block;
      float: right;
    }

    .card-compare-investment-chart .title {
      float: left;
      margin-bottom: 20px;
    }

    .card-compare-investment-chart .title h3 {
     font-weight: 800 !important;
    }

    .card-compare-investment-chart .back-action {
      float: right;
      font-size: 0.9rem;
      font-weight: 800;
      cursor: pointer;
    }

    .card-compare-investment-chart .back-action:hover {
      color: orange;
    }
    @media (min-width: 1600px){ 
      .card-compare-investment-donut {
        width: 65%;
      }
    }
    @media (max-width: 1000px) {
      .card-compare-investment-donut {
        width: 100%;
      }
    }
</style>
