import Vue from 'vue'

const AdmService = {
  // BINARY
  getResumeDashboard() {
    console.log(Vue.prototype.fbId)
    return Vue.prototype.$http.get('/api/services/app/Adm/GetDashboard',
      {
        timeout: 300000,
      })
  },
}

export default AdmService